// ** React imports
import React from 'react'

// ** MUI Imports
import Select from '@mui/material/Select'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import Accordion from "@mui/material/Accordion";

export const CustomTextField = styled(TextField)(({ theme }) => ({
  // backgroundColor: theme.colors.inputBackground,
  // color: theme.colors.inputText,
  borderRadius: '4px',
  // '& .MuiOutlinedInput-notchedOutline': {
  //   display: 'none'
  // },
  // '& .MuiOutlinedInput-root': {
  //   color: theme.colors.inputText,
  // },
  // '& > div:hover': {
  //   backgroundColor: theme.colors.inputBackground,
  // },
  // '& > div.Mui-focused': {
  //   backgroundColor: theme.colors.inputBackground,
  // },
}))

export const CustomSelect = styled(Select)(() => ({
  borderRadius: '5px',
}))

export const CustomStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '5px',
  padding: '1em',
  marginTop: '1rem',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: '.6rem'
}))

export const ThumbnailBox = styled(Box)(({ theme }) => ({
  justifyContent: 'center',
  borderRadius: '5px',
  padding: '0',
  marginBottom: 18,
  marginInline: 'auto',
  height: '100%',
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  paddingBlock: '.5rem',
  [theme.breakpoints.down('sm')]: {
    marginInline: 'auto'
  }
}))

export const CustomChip = styled(Chip)(({theme}) => ({
  // color: theme.colors.inputText,
  // borderColor: theme.colors.inputText,
  // '&.MuiChip-colorDefault': {
  //   borderColor: theme.colors.inputText,
  // },
  // '& > svg': {
  //   fill: theme.colors.inputText,
  // }
}))

export const CustomAutocomplete = styled(Autocomplete)(({theme}) => ({
  '& .MuiInputBase-root:hover': {
    backgroundColor: theme.colors.inputBackground,
  },
  '& .MuiInputBase-root.Mui-focused': {
    backgroundColor: theme.colors.inputBackground,
  }
}))

export const CustomAccordion = styled(Accordion)(({ theme }) => ({
  '&': {
  },
  '& .MuiAccordionDetails-root': {
    paddingLeft: '4rem'
  }
}))
