// ** React Imports
import { ReactNode, useState } from 'react'

// ** Next Imports
import Link from 'next/link'

// ** MUI Components
import Box from '@mui/material/Box'
import Button, { ButtonProps } from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { styled, useTheme, darken } from '@mui/material/styles'
import MuiCard, { CardProps } from '@mui/material/Card'
import InputAdornment from '@mui/material/InputAdornment'
import Grid from '@mui/material/Grid'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Layout Import
import UserBlankLayoutWithAppBar from 'src/layouts/UserBlankLayoutWithAppBar'

// ** Third Party Imports
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// ** Hooks
import { useAuth } from '@/services/useAuth'
import { CustomTextField } from '@/shared-components/Form/CustomComponents'
import { COLORS } from '@/layouts/UserThemeOptions'
import { LoginCard, LoginTitle } from '@/layouts/components/shared-components/Card/LoginCard'
import { useTranslateString } from '@/utils/TranslateString'

interface State {
  password: string
  showPassword: boolean
}

// ** Styled Components
const LinkStyled = styled(Link)(({ theme }) => ({
  fontSize: '0.75rem',
  textDecoration: 'none',
  color: theme.customBflyColors.grayInputText
}))

const RightLogoImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: { width: '172px' },
  [theme.breakpoints.up('md')]: { width: '182px' },
  [theme.breakpoints.up('xl')]: { width: '220px' }
}))

const LoginButton = styled(Button)<ButtonProps>(({ theme }) => ({
  display: 'block',
  mb: 7,
  width: '100%',
  maxWidth: '170px',
  marginInline: 'auto',
  '&:hover': {
    backgroundColor: darken(theme.palette.primary.main, 0.5)
  }
}))

// ** Yup Schema
const schema = yup.object().shape({
  username: yup.string().min(1, 'Username should have at least 1 character.').required('Username is required'),
  password: yup.string().min(5, 'Password should have at least 5 characters.').required('Password is required')
})

// remove default values if you want no initial values on render page
const defaultValues: FormData = {
  password: '',
  username: ''
}

interface FormData {
  username: string
  password: string
}

const LoginPage = () => {
  // ** State
  const [rememberMe, setRememberMe] = useState<boolean>(true)

  const [values, setValues] = useState<State>({
    password: '',
    showPassword: false
  })

  // ** Hook
  const auth = useAuth()
  const theme = useTheme()
  const TranslateString = useTranslateString()

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const onSubmit = (data: FormData) => {
    const { username, password } = data
    auth.login({ username, password, rememberMe }, () => {
      setError('username', {
        type: 'manual',
        message: 'Invalid login details'
      })
    })
  }

  return (
    <LoginCard>
      <Grid container spacing={4}>
        <Grid xs={12} md={7} lg={6} item order={{ xs: 2, md: 1 }}>
          <Box
            gap={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
              alignSelf: 'center'
            }}
          >
            <LoginTitle>
              {TranslateString('Admin')} {TranslateString('Login')}
            </LoginTitle>

            <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name='username'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <CustomTextField
                    autoFocus
                    label={TranslateString('Username')}
                    fullWidth
                    id='username'
                    value={value}
                    // onBlur={onBlur}
                    onChange={onChange}
                    error={Boolean(errors.username)}
                    placeholder=''
                    sx={{ mb: 4 }}
                  />
                )}
              />

              <Controller
                name='password'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <CustomTextField
                    label={TranslateString('Password')}
                    type={values.showPassword ? 'text' : 'password'}
                    fullWidth
                    value={value}
                    // onBlur={onBlur}
                    onChange={onChange}
                    id='password'
                    error={Boolean(errors.password)}
                    placeholder='Enter your password'
                    sx={{ mb: 4 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            edge='end'
                            onMouseDown={e => e.preventDefault()}
                            onClick={handleClickShowPassword}
                            // sx={{
                            //   color: COLORS.inputText
                            // }}
                          >
                            <Icon
                              icon={values.showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'}
                              fontSize={20}
                            />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />

              <Box
                sx={{
                  mt: 2,
                  mb: 4,
                  display: 'flex',
                  alignItems: 'top',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between'
                }}
              >
                <Typography color={theme.palette.error.main} variant='caption'>
                  {errors.username && errors.username.message}
                  <br />
                  {errors.password && errors.password.message}
                </Typography>
                <LinkStyled href='/forgot-password'>{TranslateString('Forgot Password')}?</LinkStyled>
              </Box>
              <LoginButton color='primary' size='large' type='submit' variant='contained'>
                Login
              </LoginButton>
            </form>
          </Box>
        </Grid>
        <Grid xs={12} md={5} lg={6} item order={{ xs: 1, md: 2 }}>
          <Box
            sx={{
              height: '100%',
              display: 'grid',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundSize: 'cover',
              backgroundImage: 'url("/images/login/smallbg.png")',
              padding: '40px 20px'
            }}
          >
            <RightLogoImage src='/images/logo_image.png' />
          </Box>
        </Grid>
      </Grid>
    </LoginCard>
  )
}

LoginPage.getLayout = (page: ReactNode) => <UserBlankLayoutWithAppBar>{page}</UserBlankLayoutWithAppBar>

LoginPage.guestGuard = true

export default LoginPage
