import {COLORS} from "@/layouts/UserThemeOptions";
import {Box, Card, Container} from '@mui/material'
import {PropsWithChildren} from "react";
import {Text} from '@/shared-components/Text'

export const LoginCard = ({children}: PropsWithChildren) => {
  return <Box
    sx={{
      height: '100vh',
      backgroundSize: ['contain', 'contain', 'cover'],
      backgroundColor: COLORS.loginBackground,
      backgroundImage: 'url("/images/login/loginbg.png")',
      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center',
    }}
  >
    <Container sx={{
      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center',
    }}>
        <Card sx={{
          zIndex: 1,
          width: {xs: '80%', sm: '70%'},
          minHeight: {sm: '500px'},
          borderRadius: '10px',
          backgroundColor: COLORS.primaryBackground,
          boxShadow: COLORS.boxShadow,
          border: COLORS.boxBorder,
          padding: '20px',
          color: COLORS.primaryText,
          display: 'grid',
        }}>
          {children}
        </Card>
    </Container>
  </Box>

}

export const LoginTitle = ({children}: PropsWithChildren) => {
  return <Text
    variant='h5'
    fontWeight='normal'
    sx={{
      ml: 3,
      lineHeight: 1.5,
      textTransform: 'uppercase',
      whiteSpace: 'nowrap'
    }}
  >
    {children}
  </Text>
}
